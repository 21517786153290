import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Drawer, Form, Button, message } from 'antd'
import $lodash from 'lodash'

import DrawerContents from '../drawerContents/index'

import styles from './index.module.scss'

class SaledFormDrawer extends Component {
  static propTypes = {
    form: PropTypes.object,
    detailInfo: PropTypes.object,
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }

  state = {
    selectedProductList: [], // 选择的产品
    pickupAddress: {} // 选择的地址
  }

  // 点击确定
  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { detailInfo = {}, onConfirm } = this.props
        const { selectedProductList = [], pickupAddress = {} } = this.state
        const { afterSaleOrderType, backGoodsType, uploadImageUrls = '' } = values

        if (selectedProductList.length === 0) {
          return message.warning('请选择售后商品')
        }

        values.orderId = detailInfo.orderId
        values.aftersaleGoods = $lodash.map(selectedProductList, (o) => {
          return {
            commoditySkuId: o.commoditySkuId,
            skuGoodsType: o.skuGoodsType,
            quantity: o.quantity,
            supplierId: o.supplierId,
            sellingPrice: o.taxUnitPrice
          }
        })

        if (uploadImageUrls.length > 0) {
          values.imageUrls = uploadImageUrls.split(',')
        }

        if (['REFUND_RETURN_PRODUCTS', 'CHANGE_PRODUCTS'].includes(afterSaleOrderType) && backGoodsType === 'IIASAAS' && pickupAddress.id) {
          values.pickupAddress = pickupAddress
        }

        if (afterSaleOrderType === 'ADD_DELIVER' && pickupAddress.id) {
          values.replacementAddress = pickupAddress
        }

        onConfirm(values)
      }
    })
  }

  // 监听选择的售后产品
  onChangeSelectedProducts = (list = []) => {
    this.setState({
      selectedProductList: list
    })
  }

  // 监听地址
  onChangePickupAddress = (values) => {
    this.setState({
      pickupAddress: values
    })
  }

  render () {
    const { form, detailInfo = {}, visible = false, onCancel } = this.props

    return (
      <Drawer title='售后申请' width={1300} bodyStyle={{ paddingBottom: '80px' }}
        visible={visible}
        destroyOnClose
        placement='right'
        closable={false}
        onClose={() => onCancel()}
      >
        <Form>
          {
            visible
              ? <DrawerContents form={form} detailInfo={detailInfo}
                onSelectedProduct={(list) => this.onChangeSelectedProducts(list)}
                onChangePickupAddress={(values) => this.onChangePickupAddress(values)}
              />
              : null
          }
        </Form>

        <div className={`${styles.btns}`}>
          <Button onClick={() => onCancel()}>取消</Button>

          <Button type='primary' style={{ marginLeft: '16px' }} onClick={() => this.handleConfirm()}>申请售后</Button>
        </div>
      </Drawer>
    )
  }
}

const SaledDrawer = Form.create()(SaledFormDrawer)

export default SaledDrawer
