import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, InputNumber } from 'antd'

class DeliverySetForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }

  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onConfirm(values.expectDeliveryPeriodDays)
      }
    })
  }

  render () {
    const { getFieldDecorator } = this.props.form

    const formItemLayout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 }
    }

    return (
      <Modal title='设置货期规则'
        visible
        maskClosable={false}
        onOk={() => this.handleConfirm()}
        onCancel={() => this.props.onCancel()}
      >
        <Form>
          <Form.Item label='设置全部货期为' {...formItemLayout}>
            {
              getFieldDecorator('expectDeliveryPeriodDays', {
                rules: [
                  { required: true, message: '请设置货期' }
                ]
              })(<InputNumber style={{ width: 'calc(100% - 30px)' }} min={0} />)
            }
            <span style={{ marginLeft: '8px' }}>工作日</span>
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

const DeliverySetModal = Form.create()(DeliverySetForm)

export default DeliverySetModal
