import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Steps, Timeline, Button } from 'antd'

import TimelineInner from '../timelineInner/index'

import Api from '@/common/api/index'

const { Step } = Steps

export default class SaledProgressModal extends Component {
  static propTypes = {
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func
  }

  state = {
    current: 0,
    nodes: [],
    tableData: []
  }

  componentDidMount () {
    this.getPurchaseOrderSaledProgressDetails()
  }

  // 获取云采购售后进度详情
  getPurchaseOrderSaledProgressDetails = async () => {
    const { detailInfo = {} } = this.props
    const res = await Api.getPurchaseOrderSaledProgressDetails({ id: detailInfo.id })
    const { code, data } = res
    if (+code === 10000) {
      let _current = 0
      if (data && data.handleNodes) {
        const n = data.handleNodes.findIndex((o) => { return !o.isComplete })
        _current = n === -1 ? data.handleNodes.length - 1 : n
      }

      this.setState({
        nodes: data.handleNodes || [],
        tableData: data.handleNodeDetails || [],
        current: _current
      })
    }
  }

  render () {
    const { onCancel } = this.props
    const { current = 0, nodes = [], tableData = [] } = this.state

    return (
      <Modal title='售后进度' width={800} bodyStyle={{ height: '500px', overflowY: 'auto' }}
        visible
        maskClosable
        onCancel={() => onCancel()}
        footer={[<Button key={'btns-cancel'} onClick={() => onCancel()}>关闭</Button>]}
      >
        <div style={{ marginBottom: '36px' }}>
          <Steps size='small' current={current}>
            {
              nodes.map((item, i) => (
                <Step key={`stepItem-${i}`} title={item.action} />
              ))
            }
          </Steps>
        </div>
        <div>
          <Timeline>
            {
              tableData.map((item, i) => (
                <Timeline.Item key={`timelineItem-${i}`}>
                  <TimelineInner title={item.node} desc={item.describe} time={item.time} />
                </Timeline.Item>
              ))
            }
          </Timeline>
        </div>
      </Modal>
    )
  }
}
