import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Drawer, Icon } from 'antd'

import Cart from '@/pages/purchase/cart/index'
import EnquireOrderContents from '../enquireOrderContents/index'

import styles from './index.module.scss'

export default function AffixMenu (props) {
  const { history } = props
  const [cartDrawerVisible, setCartDrawerVisible] = useState(false)
  const [enquireOrderVisible, setEnquireVisible] = useState(false)

  return (
    <>
      <div className={`${styles.affixMenuWrap}`}>
        <ul>
          <li className={`${styles.menuItem}`} onClick={() => setCartDrawerVisible(true)}>
            <Icon type="shopping-cart" style={{ fontSize: '24px' }} />
            <p>购物车</p>
          </li>
          <li className={`${styles.menuItem}`} onClick={() => setEnquireVisible(true)}>
            <Icon type="file-search" style={{ fontSize: '24px' }} />
            <p>询价单</p>
          </li>
          <li className={`${styles.menuItem}`}>
            <Icon type="message" style={{ fontSize: '24px' }} />
            <p>客服</p>
          </li>
        </ul>
      </div>

      <Drawer title={'我的购物车'}
        width={'80%'}
        visible={cartDrawerVisible}
        placement="right"
        onClose={() => setCartDrawerVisible(false)}
      >
        {
          cartDrawerVisible
            ? <Cart history={history} />
            : null
        }
      </Drawer>

      <Drawer title='我的询价单' width={'80%'}
        visible={enquireOrderVisible}
        placement="right"
        onClose={() => setEnquireVisible(false)}
      >
        {
          enquireOrderVisible
            ? <EnquireOrderContents />
            : null
        }
      </Drawer>
    </>
  )
}

AffixMenu.propTypes = {
  history: PropTypes.object
}
