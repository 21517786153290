import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Input } from 'antd'

const FormItem = Form.Item
const { TextArea } = Input

class RemarkForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    outboundRemarks: PropTypes.string,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func
  }

  state = {}

  componentDidMount () { }

  // 点击确定
  handleConfirm = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onConfirm(values)
      }
    })
  }

  render () {
    const { form, outboundRemarks, onCancel } = this.props
    const { getFieldDecorator } = form

    return (
      <Modal title='拣货单备注' width={800} bodyStyle={{ padding: '16px' }}
        visible
        maskClosable={false}
        onCancel={() => onCancel()}
        onOk={() => this.handleConfirm()}
      >
        <Form>
          <FormItem>
            {
              getFieldDecorator('remarks', {
                initialValue: outboundRemarks || ''
              })(
                <TextArea rows={4} maxLength={200} placeholder='请输入内容，不超过200字...' autoComplete='off' />
              )
            }
          </FormItem>
        </Form>
      </Modal>
    )
  }
}

const RemarkModal = Form.create()(RemarkForm)

export default RemarkModal
