import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import UploadThumbnail from '@/components/uploadThumbnail/index'

import styles from './index.module.scss'

export default class TimelineInner extends Component {
  static propTypes = {
    title: PropTypes.string,
    desc: PropTypes.string,
    imageUrls: PropTypes.array,
    time: PropTypes.string
  }

  state = {}

  componentDidMount () {}

  render () {
    const { title, desc, time, imageUrls = [] } = this.props
    return (
      <>
        <div>
          <label className={`${styles.lineTitle}`}>[{ title }]</label>
          <span className={`${styles.lineDesc}`}>{ desc }</span>
        </div>
        {
          imageUrls && imageUrls.length > 0
            ? <div style={{ marginTop: '6px' }}>
              {
                imageUrls.map((item, i) => (
                  <div className={`${styles.pictureWrap}`} key={`IMAGEITEM-${i}`}>
                    <UploadThumbnail imageUrl={item} />
                  </div>
                ))
              }
            </div>
            : null
        }
        <p className={`${styles.lineTime}`}>{ time ? moment(time).format('YYYY-MM-DD HH:mm:ss') : null }</p>
      </>
    )
  }
}
